<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="totalList">
          <div class="item d-flex flex-row justify-space-between mt-5" v-for="(item,index) in installmentList" :key='index'>
              <div class="s1" v-if="item.number != 11">Phase #{{item.number}}</div>
                 <div class="s1" v-else>Supplementary phase</div>
              <div class="s2">{{item.startTimeStr}} - {{item.endTimeStr}} UTC+0</div>
              <div class="s3" :class="{distributing:item.status == 1,claimable:item.status ==0 }">{{getStatus(item.status)}}</div>

      </div>
      </div>

  
    </v-card>
  
  </div>
</template>
<script>
import api from "@/api";
export default {
  data: () => ({
    open: true,
    text:''
  }),
  props: {
 installmentList: {
      type: Array,
    },
    
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted(){
      console.log('ssss',this.installmentList);
  },
  methods: {
      getStatus(status){
          switch (status) {
              case 0:
                  return 'Claimable' 
                case 1:
                  return 'Distributing'
                case 2:
                  return 'Received'
                case 3:
                  return 'Expired' 
                case 4:
                  return 'Unstarted'         
              default:
                  break;
          }

      },

    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 274px;
  width: 630px;
  .totalList{
      margin:44px 30px;
      .item{
        width: 100%;
          .s1{
               width: 25%;
               text-align: center;
                font-family: Helvetica;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                line-height: 20px;
                letter-spacing: 0px;
                color: #270645;
          }
          .s2{
             width: 50%;
              text-align: center;
            font-family: Helvetica;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 20px;
            letter-spacing: 0px;
            color: #270645;
          }
          .s3{
            width: 25%;
            text-align: right;
            font-family: Helvetica;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 20px;
            letter-spacing: 0px;
            color: #868686;
       
          }
          .distributing{
              color: #33cc33;
          }
          .claimable{
              	color: #270645;
          }

      }
  }
 
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
 
  &.mobile {
   
   
  }
}


</style>




