<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
       <div class="font-18"> Congratulations, your claim has been submitted!</div>
       <div class="font-13 mt-6">Please kindly wait patiently for the $UPTICK tokens to be transferred to you. To view them, simply switch the Keplr wallet to the Uptick node via the dropdown menu. </div>
       <div class="font-13 mt-8">Your default Uptick wallet address is: <span>{{uptickAddress}}</span> </div>
       <div class="font-13 mt-8">You can also import this wallet into MetaMask and link it to the Uptick Chain Marketplace to participate in the recently launched Uptick Chain Marketplace Genesis Campaign. </div>
       <div class="font-bold-13 mt-8">How to get involved?</div>
       <div class="font-13 mt-1">Be among the first 1000 content creators, or one of the initial 3000 verified Twitter users to qualify to receive an exclusive 'Welcome Gift' NFT which entitles you to a variety of future Uptick Ecosystem perks. </div>
       <div class="font-13 mt-1">We hope you enjoy your journey through the Uptick NFT Multiverse.</div>
      </div>
      <div class="footer">
        <button class="sub "  submit @click="subCommit">Explore on Uptick Chain Marketplace</button>
      </div>
    </v-card>
  
    <uComponents  ref="ucom"></uComponents>
  </div>
  
</template>
<script>
import api from "@/api";
  import {getAddress } from "../../../src/keplr/wallet";
export default {
  data: () => ({
    newOpen: true,
  nftName:"",
  isShowFalse: false,
  uptickAddress:''

   
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  
  async mounted() {
    console.log('wwwwwwww');  
       let accountInfo = await getAddress(0);
       this.uptickAddress = accountInfo.address
  },
  methods: {
    subCommit(){
      this.$emit("getOpen", false);
      window.open('https://uptick.upticknft.com/promo', "_blank");

    },
  
    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 239px;
  width: 800px;

  .top {
     margin: 68px 52px 0px;
     .font-18{
       font-family: Helvetica;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #270645;
     }
     .font-13{
       	font-family: Helvetica;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 23px;
        letter-spacing: 0px;
        color: #270645;
        span{
        color: #7800f4;;
        }
     }
     .font-bold-13{
       font-family: Helvetica;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 23px;
        letter-spacing: 0px;
        color: #270645;

     }

  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin:0 auto;

    .sub {
      margin: 40px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      font-family: Helvetica;
	font-size: 15px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #ffffff;
    }
  }
  &.mobile {
    .footer {
       width:100%;margin:0;
      .sub {
         margin-left:10%;
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 5px;
    
    // box-shadow: 0 0 10px #fff;
    // border-radius: 50%;
}
</style>

